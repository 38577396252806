import React from 'react';

const Home = () => {
  return (
    <>
    <section className='home' id="home">

      <div className='content'>
        <h3>Welcome to our <span>Kindergarten</span></h3>
        <p>a nurturing and vibrant haven for young learners!</p>
        <p>Dedicated to providing a warm, welcoming environment where children can explore, grow, and thrive!</p>
        {/*<a href='#about' className='btn'>Learn more</a>*/}
      </div>

      {/*
      <div className='image'>
        <img src={playpen} alt='image of play area' />
      </div>
      */}

      <div className="custom-shape-divider-bottom-1714611753">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
        </svg>
      </div>
    </section>
    </>
  );
};

export default Home;